/* You can add global styles to this file, and also import other style files */
html,
body {
  padding: 0;
  height: 100%;
}

@import 'assets/styles/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  @apply text-sm text-black80; // 14px | Black / High Emphasis
  * {
    @apply border-neutral-200;
  }
}

@layer base {
  h1 {
    font-family: Arial;
    font-size: 6rem;
    font-style: italic;
    line-height: 9rem;
    letter-spacing: -1.5px;
  }
  h2 {
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 5.625rem;
    letter-spacing: -0.5px;
  }
  h3 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  h4 {
    font-size: 2.125rem;
    line-height: 3.125rem;
    letter-spacing: 0.25px;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
    letter-spacing: 0.15px;
  }
}

@layer utilities {
  .text-title-1 {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
  }
  .text-title-2 {
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.1px;
  }
  .text-overline {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.75rem;
    letter-spacing: 1.5px;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-between-center {
    @apply flex justify-between items-center;
  }
}
