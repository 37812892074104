.mat-divider {
  border-top-color: #e5e5e5 !important;
}

.mat-expansion-indicator:after {
  border-color: #000000cc !important;
}
.mat-expansion-panel-header-title {
  font-weight: 500;
}

.mat-expansion-panel-header,
.mat-expansion-panel-body {
  padding: 0 12px !important;
}

.mat-expansion-panel-header {
  height: 36px !important;
}
