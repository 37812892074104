/* material-icons-base-400-normal*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src:
    url('./fonts/material-icons-base-400-normal.woff2') format('woff2'),
    url('./fonts/material-icons-base-400-normal.woff') format('woff');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible !important;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* material-icons-outlined-all-400-normal*/
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src:
    url('./fonts/material-icons-outlined-all-400-normal.woff2') format('woff2'),
    url('./fonts/material-icons-outlined-all-400-normal.woff') format('woff');
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible !important;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
