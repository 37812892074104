.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-flex:hover {
  .mat-form-field-outline.mat-form-field-outline-thick {
    color: #42a5f5;
  }

  & ~ {
    .mat-form-field-underline .mat-form-field-ripple {
      background-color: #42a5f5;
    }
  }
}
