div[role='tab'][aria-selected='true'] {
  .mat-tab-label-content {
    color: #0d47a1 !important;
  }
}

div.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-tab-header {
  width: fit-content;
}

.mat-tab-body-wrapper,
.mat-tab-body {
  width: 100%;
}
